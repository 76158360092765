import { ChangeDetectorRef, Injectable, OnInit } from '@angular/core';

import { ActivatedRouteSnapshot, ChildActivationEnd, Route, Router, RoutesRecognized } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class TabsControlService implements OnInit{
  public tabs: Tab[] = [];  //Array de paginas o tabs de la Aplicacion
  selectedIndex : number = 0;

  constructor(
    //private cd: ChangeDetectorRef,
    private route: Router,
    ) {

    // listen to routing change event to attach new tabs or activate a new one
    route.routeReuseStrategy.shouldReuseRoute = () => false;
    route.events.subscribe(val => {
      this.checkAndAddRouteTab(val);
    });
  }

  ngOnInit(): void {
     // get all routes to mock a navigation
     //this.routes = this.route.config;
     //console.log("routes config", this.routes);
  }



  //Tabs Control
  disposeTab(tab: Tab) {
    if (this.tabs.length >= 0) {
      this.tabs = this.tabs.filter(item => item.key !== tab.key);

/*      if (tab.active) {
        // deactivate all tabs
        this.deactivateTabs();
        this.route.navigateByUrl(this.tabs[this.tabs.length - 1].route.path);
      }
      */
    }
  }

  /*
  mouseOverTab(tab: Tab) {

    //this.currentHoverTabKey = tab ? tab.key : null;
  }*/

  checkAndAddRouteTab(val) {
    // get the component to activate by the route
    let comp = null;
    let rc = null;
    let params = null;
    let queryParams = null;
    let url = null;
    let data = null;
    let firstChild = null;

    //Pregunta por la instancia de los eventos solo que nos interesa
    if (val instanceof RoutesRecognized){
      comp = val.state.root.firstChild.component;
      rc = val.state.root.firstChild.routeConfig;
      firstChild = val.state.root.firstChild.children;
      params = {...val.state.root.firstChild.params};
      params = Object.assign(params, this.extractAllValuesFromSnapshotChildren(val.state.root.children, 'params'));
      queryParams = {...val.state.root.firstChild.queryParams};
      queryParams = Object.assign(queryParams, this.extractAllValuesFromSnapshotChildren(val.state.root.children, 'queryParams'));
      
      data = {...val.state.root.data};
      data = Object.assign(data, this.extractAllValuesFromSnapshotChildren(val.state.root.children, 'data'));
      url = val.state.root['_routerState'].url.replace(/#/g, '');
      
    } else if (val instanceof ChildActivationEnd) {
      
      comp = val.snapshot.firstChild.component;
      rc = val.snapshot.routeConfig;
      firstChild = val.snapshot.children;
      params = {...val.snapshot.params};
      params = Object.assign(params, this.extractAllValuesFromSnapshotChildren(val.snapshot.children, 'params'));
      queryParams = {...val.snapshot.queryParams};
      queryParams = Object.assign(queryParams, this.extractAllValuesFromSnapshotChildren(val.snapshot.children, 'queryParams'));
      
      data = {...val.snapshot.data};
      data = Object.assign(data, this.extractAllValuesFromSnapshotChildren(val.snapshot.children, 'data'));
      url = val.snapshot['_routerState'].url.replace(/#/g, '');;
    } else {
      return;
    }
    
    if (!comp) return;

    //Carga las paginas normales y los que son layout=tab
    if (data && data.layout === 'innerTab') return;

    //let rcKey = rc["path"]; // Crea la Clave para el Caso de Uso
    let rcKey = rc["path"] + this.concatSubPathChildren(firstChild);

    for (const key in params) {
      rcKey = rcKey.replace(new RegExp(":" + key, "g"), params[key]);
    }
    for (const key in queryParams) {
      rcKey = rcKey.replace(new RegExp(":" + key, "g"), queryParams[key]);
    }

    /*try{
      rcKey = eval(rcKey);
    } catch (e){console.error(e);}
    */

    const tabFinded = this.tabs.find(tab => tab.key == rcKey);

    if (tabFinded == null) {
      // if not, push it into the tab array
      const tab = {
        key: rcKey,
        innerTabs : [],
        innerIndexSelected : 0,
        innerCurrentTabKey : ''
      };

      const innerTab = {
        component: comp,
        componentInstance : null,
        data: data,
        params: params,
        queryParams : queryParams,
        key: "Datos",
        route: rc,
        url : url,
      };
      tab.innerTabs.push(innerTab);  //Agrega como primer hijo al componente padre
      
      this.tabs.push(tab);
      
    } else {
      tabFinded.innerIndexSelected = 0; //Vuelve a seleccionar el indice 0
    }
    this.selectedIndex = this.tabs.findIndex(tab => tab.key == rcKey);
  }

  extractAllValuesFromSnapshotChildren(children: ActivatedRouteSnapshot[], fieldName) {
    var data = {};
    if (children && children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        data = Object.assign(data, {...child[fieldName] }); //params, queryParams, data
        
        const dataHijo = this.extractAllValuesFromSnapshotChildren(child.children, fieldName);
        data = Object.assign(data, dataHijo);
      }
    }
    return data;    
  }

  concatSubPathChildren(children: ActivatedRouteSnapshot[]) {
    let path = "";

    if (children && children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        path += "/" + children[i].routeConfig.path + this.concatSubPathChildren(children[i].children);
      }
    }
    return path;
  }

  deactivateTabs() {
    //this.tabs.forEach(tab => (tab.active = false));
  }

  getTabSelected() : Tab{
    return this.tabs[this.selectedIndex];
  }

  clear() {
    this.tabs = [];
  }
  
}
export interface Tab {
  key: string;
  innerTabs : InnerTab[],  
  innerIndexSelected : number //Para saber el indice que esta seleccionado
//  innerCurrentTabKey : string //Para saber que hijo activar por su key
}

export interface InnerTab {
  //name: string;
  component: any;
  componentInstance : any;
  data: any,
  params: any,
  queryParams: any,
  //active: boolean;
  route: Route;
  url: string,
  key: string;
}
