import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { ReqResLogin } from './../models';
import { BehaviorSubject, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TabsControlService } from './tabs.service';



const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);
  private userToken = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient,
    private tabsControlService : TabsControlService   //Injeccion temporal, para eliminar tabs
    ) {
    this.checkToken();
  }

  get isLogged(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  get userTokenValue(): string {
    return this.userToken.getValue();
  }

  registerUser(data: any, type: string): Observable<any> {
    return this.http.post<any>(`${environment.url}register/${type}`, data)
    .pipe(map(res => res));
  }

  login(credential): Observable<ReqResLogin> {
    return this.http
    .post<ReqResLogin>(`${environment.url}login`, credential)
    .pipe(map(data => {
      if (data.ok === true) {
        this.saveToken(data.token);
        this.setUser(data.usuario.per_codigo);
        this.setUserData(data.usuario);
        this.loggedIn.next(true);
        this.userToken.next(data.token);
        return data;
      } else {
        return data;
      }
    }),
    catchError((err) => this.handlerError(err))
    );
  }

  sendResetPassword(email: any): Observable<any> {
    return this.http.post<any>(`${environment.url}send-reset-password/${email}`, { })
    .pipe(map(res => res));
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post<any>(`${environment.url}reset-password`, data)
    .pipe(map(res => res));
  }
  
  getEmailByToken(token): Observable<any>{
    return this.http.get<any>(`${environment.url}app/email-by-token/` + token);
    //.pipe(map(res => res));
  }

  setUser(user: any): void {
    const userString = JSON.stringify(user);
    sessionStorage.setItem('currentUser', userString);
  }

  setUserToken(token): void {
    this.userToken.next(token)
    // sessionStorage.setItem('accessToken', token);
  }

  setToken(token): void {
    // this.userToken.next(token)
    sessionStorage.setItem('accessToken', token);
  }

  setUserData(user): void {
    const userString = JSON.stringify(user);
    sessionStorage.setItem('userData', userString);
  }

  setLoginType(loginType: string): void {
    sessionStorage.setItem('currentLoginType', loginType);
  }

  getLoginType(): string {
    return sessionStorage.getItem('currentLoginType');
  }

  getToken(): string {
    return sessionStorage.getItem('accessToken');
  }

  getCurrentUser(): any {
    const userString = sessionStorage.getItem('currentUser');
    if (!(userString === null || userString === undefined)) {
      const user = JSON.parse(userString);
      return user;
    } else {
      return null;
    }
  }

  getCurrentUserData(): any {
    const userString = sessionStorage.getItem('userData');
    if (!(userString === null || userString === undefined)) {
      const user: any = JSON.parse(userString);
      return user;
    } else {
      return null;
    }
  }

  logout(): void {
    window.sessionStorage.clear();

    this.tabsControlService.clear();
    
    this.loggedIn.next(false);
    this.userToken.next(null);
  }

  // Se usa para que el interceptor funcione
  /* NO TOCAR O BORRAR */
  private checkToken(): void {
    const userToken = this.getToken();
    const isExpired = helper.isTokenExpired(userToken);
    // console.log('isExpired -->', isExpired);
    isExpired ? this.logout() : this.loggedIn.next(true);
    if (isExpired) {
      this.logout();
    } else {
      this.loggedIn.next(true);
      this.userToken.next(this.getToken());
    }
  }


  private saveToken(token): void {
    sessionStorage.setItem('accessToken', token);
  }

  private handlerError(err): Observable<never> {
    console.log('ERROR', err);
    let errorMessage = 'An error ocurred retrienving data';
    if (err) {
      errorMessage = `Error: code ${err.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
