export const navigationTopControl = [
            
/*    {
        id: 'persona-list',
        title: 'Personas',
        type: 'collapsable',
        children : [
            {
                id       : 'persona-hija-list',
                title    : 'Usuarios',
                type     : 'item',
                url      : '/general/persona-hija-list/usuarios'
            },
            {
                id       : 'persona-hija-list',
                title    : 'Clientes',
                type     : 'item',
                url      : '/general/persona-hija-list/clientes'
            },
            {
                id       : 'persona-hija-list',
                title    : 'Proveedores',
                type     : 'item',
                url      : '/general/persona-hija-list/proveedores'
            },
            {
                id       : 'persona-hija-list',
                title    : 'Prestador de Servicios',
                type     : 'item',
                url      : '/general/persona-hija-list/prestadores_servicios'
            },
            {
                id       : 'persona-hija-list',
                title    : 'Especialistas',
                type     : 'item',
                url      : '/general/persona-hija-list/especialistas'
            }
        ]
    },
    {
        id       : 'generales',
        title    : 'Generales',
        type     : 'collapsable',
        children : [
            {
                id       : 'ciudades-list',
                title    : 'Ciudades',
                type     : 'item',
                url      : '/general/tipo-list/ciudad'
            },
            {
                id       : 'especialidades-list',
                title    : 'Especialidades',
                type     : 'item',
                url      : '/general/tipo-list/especialidad'
            },
            {
                id       : 'idiomas-list',
                title    : 'Idiomas',
                type     : 'item',
                url      : '/general/tipo-list/idioma'
            },
            {
                id       : 'paieses-list',
                title    : 'Paises',
                type     : 'item',
                url      : '/general/tipo-list/pais'
            },
            {
                id       : 'perfiles-list',
                title    : 'Perfiles',
                type     : 'item',
                url      : '/general/tipo-list/perfil'
            },
            {
                id       : 'zonas-geograficas-list',
                title    : 'Zonas geograficas',
                type     : 'item',
                url      : '/general/tipo-list/zona_geografica'
            },
            {
                id       : 'perfiles-preguntas-list',
                title    : 'Preguntas para el Usuario',
                type     : 'item',
                url      : '/general/tipo-list/perfil_preguntas'
            },
            {
                id       : 'opciones-perfiles-list',
                title    : 'Preguntas del Perfil',
                type     : 'item',
                url      : '/general/perfil-opcion-list/perfil'
            },
            {
                id       : 'monedas-list',
                title    : 'Monedas',
                type     : 'item',
                url      : '/general/moneda-list'
            },
            {
                id       : 'tipos-servicios-list',
                title    : 'Tipos de Servicios',
                type     : 'item',
                url      : '/general/tipo-servicio-list/tipo_servicio'
            },
            {
                id       : 'turnos-list',
                title    : 'Turnos',
                type     : 'item',
                url      : '/general/tipo-servicio-list/turno'
            }
        ]
    },
    {
        id       : 'chat',
        title    : 'Chat',
        type     : 'collapsable',
        children : [
            {
                id       : 'chat-panel',
                title    : 'Chat',
                type     : 'item',
                url      : '/chat'
            },
            
        ]
    },*/
];
