import { FuseNavigation } from '@fuse/types';

export const navigationControl: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Panel de Control',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        role: ['a'],
        children: [
            {
                id: 'dashboard-analytics',
                title: 'Dashboard',
                type: 'collapsable',
                role: ['a'],
                children: [
                    {
                        id: 'panel-control-adquisiciones',
                        title: 'Adquisiciones',
                        type: 'item',
                        url: '/home/analytics-adquisiciones/pctrl',
                        role: ['a']
                    },
                    {
                        id: 'panel-control-supervisores-dashboard',
                        title: 'Supervisores',
                        type: 'item',
                        url: '/home/analytics-supervisores/pctrl',
                        role: ['a']
                    }
                ]
            },
            {
                id: 'persona-list',
                title: 'Usuarios Activos',
                type: 'collapsable',
                role: ['a'],
                children: [
                    {
                        id: 'usuarios-registrados',
                        title: 'Usuarios Activos',
                        type: 'item',
                        url: '/cuenta/usuarios-registrados-list/usuarios_registrados',
                        role: ['a']
                    },
                    {
                        id: 'especialista-servicio',
                        title: 'Servicios de Especialistas',
                        type: 'item',
                        url: '/especialista-servicio/especialista-servicio-list',
                        role: ['a']
                    }
                ]
            },
            {
                id: 'persona-list',
                title: 'Estado de Usufructos',
                type: 'collapsable',
                role: ['a'],
                children: [
                    {
                        id: 'estado-usufructos',
                        title: 'Estado de Usufructos',
                        type: 'item',
                        url: '/usufructo/usufructo-list',
                        role: ['a']
                    }
                ],
            },
            {
                id: 'consltas-dinamicas-listados',
                title: 'Listados',
                type: 'collapsable',
                role: ['a'],
                children: [
                    {
                        id: 'consulta-dinamica-listado-afiliados',
                        title: 'Listado de Afiliados',
                        type: 'item',
                        url: '/comandos-sql/comandos-sql-play/consulta-dinamica/5',
                        role: ['a']
                    },
                    {
                        id: 'consulta-dinamica-listado-compras-pendientes-de-pago',
                        title: 'Listado de Compras pendientes de pago',
                        type: 'item',
                        url: '/comandos-sql/comandos-sql-play/consulta-dinamica/6',
                        role: ['a']
                    },
                    {
                        id: 'listado-de-adquisicion',
                        title: 'Listado de Adquisicion',
                        type: 'item',
                        url: '/comandos-sql/comandos-sql-play/consulta-dinamica/8',
                        role: ['a']
                    }
                ],
            },
            {
                id: 'sugerencia-list-menu',
                title: 'Sugerencias recibidas',
                type: 'collapsable',
                role: ['a'],
                children: [
                    {
                        id: 'sugerencia-list',
                        title: 'Sugerencias',
                        type: 'item',
                        url: '/general/tipo-list/sugerencia',
                        role: ['a']
                    }
                ],
            },


        ]
    }
];
