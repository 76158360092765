import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
// import { ScrollContainerComponent } from 'app/components/scroll-container/scroll-container.component';

import { AuthGuard } from 'app/guards/auth.guard';
// import { RoleGuardService as RoleGuard } from './guards/role.guard';
import { environment } from 'environments/environment';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from 'app/fake-db/fake-db.service';

import { CodeEditorModule } from '@ngstack/code-editor';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AdminInterceptor } from './interceptors/admin-interceptor';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule, AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { PipesModule } from './pipes/pipes.module';
import { I } from '@angular/cdk/keycodes';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnuncioUsufructoModule } from './admin/general/anuncio-usufructo/anuncio-usufructo.module';
import { AccionesModule } from './admin/acciones/acciones.module';



const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled', 
    scrollOffset: [0, 64]
};

const config: SocketIoConfig = { 
    url: environment.urlBase, 
    options: { transports: ['websocket', 'polling'] }
};

const appRoutes: Routes = [
    {
        path: '', redirectTo: '/authentication/login', pathMatch: 'full' 
    }, {
        path: 'authentication', loadChildren: () => import('./pages/authentication/authentication.module')
        .then(m => m.AuthenticationModule) 
    }, {
        path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), 
        canActivate: [AuthGuard]
    }, {
        path: 'general', loadChildren: () => import('./admin/general/general.module').then(m => m.GeneralModule), 
        canActivate: [AuthGuard]
    }, {
        path: 'administrador', loadChildren: () => import('./admin/administrador/administrador.module')
        .then(m => m.AdministradorModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'proveedor', loadChildren: () => import('./admin/proveedor/proveedor.module').then(m => m.ProveedorModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'import-export', loadChildren: () => import('./admin/configuraciones/importexport/importexport.module')
        .then(m => m.ImportexportModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'notificaciones', loadChildren: () => import('./admin/configuraciones/notificaciones/notificaciones-app.module')
        .then(m => m.NotificacionesAppModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'programador-tareas', loadChildren: () => import('./admin/programador-tareas/programador-tareas.module')
        .then(m => m.ProgramadorTareasModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'noticias', loadChildren: () => import('./admin/noticias/noticias.module').then(m => m.NoticiasModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'chat-member', loadChildren: () => import('./admin/chat-member/chat-member-list/chat-member-list.module')
        .then(m => m.ChatMemberListModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'cuenta', loadChildren: () => import('./control/cuenta/cuenta.module').then(m => m.CuentaModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'usufructo', loadChildren: () => import('./control/usufructo/usufructo.module').then(m => m.UsufructoModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'especialista-servicio', loadChildren: () => import('./control/especialista-servicio/especialista-servicio.module')
        .then(m => m.EspecialistaServicioModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'comandos-sql', loadChildren: () => import('./admin/configuraciones/comandos-sql/comandos-sql.module')
        .then(m => m.ComandosSqlModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'motor-regla', loadChildren: () => import('./admin/configuraciones/motor-regla/motor-regla.module')
        .then(m => m.MotorReglaModule), 
        canActivate: [AuthGuard] 
    }, {
        path: 'control-panel-adquisiciones-informes', loadChildren: () => import('./pages/informes/control-adquisiciones/control-adquisiciones.module')
        .then(m => m.ControlAdquisicionesModule)
    }, {
        path: 'control-panel-supervisores-informes', loadChildren: () => import('./pages/informes/control-supervisores/control-supervisores.module')
        .then(m => m.ControlSupervisoresModule)
    }, {
        path: 'pages', loadChildren: () => import('./pages/pages.module')
        .then(m => m.PagesModule)
    }, {
        path: 'pages', loadChildren: () => import('./pages/pages.module')
        .then(m => m.PagesModule)
    }, {
      path: 'anuncio-usufructo/:usuarioId/:anuncioId', loadChildren: () => import('./admin/general/anuncio-usufructo/anuncio-usufructo.module')
      .then(m => m.AnuncioUsufructoModule),
      canActivate: [AuthGuard]
    },
    { path: 'prueba-botones', loadChildren: () => import('./admin/acciones/acciones.module').then(m => m.AccionesModule) },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        RouterModule.forRoot(appRoutes, routerOptions),
        // ScrollContainerComponent.forChild(),
        // PagesModule..forChild(ScrollContainerComponent),
        SocketIoModule.forRoot(config),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        PipesModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        // CodeEditorComponent
        CodeEditorModule.forRoot(),
        NgxJsonViewerModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        //PWA
        AngularFirestoreModule,
        ServiceWorkerModule.register('ngsw-worker.js',{enabled:environment.production}),
        FormsModule,

        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireMessagingModule,
        AngularFirestoreModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AdminInterceptor, multi: true},
        //[{provide: APP_BASE_HREF, useValue: '/'}]
    ],
    bootstrap: [AppComponent]
})
export class AppModule
{
}
