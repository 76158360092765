import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthService } from 'app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TiposService {

  constructor(public authService: AuthService, private http: HttpClient) {}

  getByIdTipos = (sigla: string, id: number) => {
    return this.http.get<any>(environment.url + 'tipo/' + sigla + '/' + id) ;
  }

  getListTipos = (sigla: string) => {
    return this.http.get<any>(environment.url + 'tipos/' + sigla);
  }

  getTipos = (sigla: string, query: string, page: number, limit: number) => {
    if (query === '') { query = '%20'; }
    return this.http.get<any>(environment.url + 'tipos/' + sigla + '/' + query + '/' + limit + '/' + page);
  }

  saveTipos = (data: any, sigla: string) => {
    return this.http.put<any>(environment.url + 'tipo/' + sigla, data);
  }

  deleteTipos = (id: number, sigla: string) => {
    return this.http.delete<any>(environment.url + 'tipo/' + sigla + '/' + id);
  }
  

}
