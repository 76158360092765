import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { navigationTopAdmin } from '../../../app/navigation/navigation.top.admin';
import { navigationTopControl } from '../../../app/navigation/navigation.top.control';
import { NotificationService } from '../../../app/services/notification.service';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../../../app/services/auth.service';

@Component({
    selector   : 'shortcuts-maestros',
    templateUrl: './shortcuts-maestros.component.html',
    styleUrls  : ['./shortcuts-maestros.component.scss']
})
export class FuseShortcutsMaestrosComponent implements OnInit, AfterViewInit, OnDestroy
{
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;

    @Input()
    layout = 'vertical';
    
    @Input()
    navigation: any;

    @Input() 
    permission: string;

    @ViewChild('searchInput')
    searchInputField;

    @ViewChild('shortcutsMaestros')
    shortcutsEl: ElementRef;

    // Chat listeners
    unReadChatsSessions = {}; //Key par session, user values
    unReadChatsCount = 0;
    chatNewMessageListeners;
    chatRemoveUnreadListeners;
  
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,
        private notificationService: NotificationService,
        private socket: Socket,
        private authService: AuthService
    ) {
        // Set Permission to access case use
        this.permission = sessionStorage.getItem('currentLoginType');
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log("local st", localStorage.getItem('isAdmin'));
        if ( localStorage.getItem('isAdmin') !== 'true'){   // Aqui hay algo raro, por que la validacion funciona al contrario
            this.shortcutItems = navigationTopAdmin;
        } else {
            this.shortcutItems = navigationTopControl;
        }

        this.initializeSocketOnMessage();
    }


    ngAfterViewInit(): void {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if ( this._mediaObserver.isActive('gt-sm') )
                {
                    this.hideMobileShortcutsPanel();
                }
            });
    }


    // Chats - Listener para saber si llega un nuevo mensaje a la aplicación
    initializeSocketOnMessage(): void {
        this.chatNewMessageListeners = (messageData) => {
        console.log('principal chat new message ', messageData);

        };

        this.chatRemoveUnreadListeners = (removeUnreads) => {

        // Verifica si el chat removido, corresponde al usuario actual, para disminuir el contador
        if (Number(removeUnreads.cht_member) === Number(this.authService.getCurrentUser())) {
            delete this.unReadChatsSessions['sesion'];
            this.unReadChatsCount = 0;
            for (const key in this.unReadChatsSessions) {
                if (key) {
                    this.unReadChatsCount ++;
                }
            }
        }

        // Verifica si el chat removido, no corresponde a la misma session del chat eliminado, para aumentar el contador
        const removeUnreadUser = removeUnreads['chatMessagesUnreads'].filter( cmu => {
            return cmu['chr_member'] === this.authService.getCurrentUser();
        });
        if (removeUnreadUser.length > 0) {
            if (Number(removeUnreadUser[0].chr_member) === Number(this.authService.getCurrentUser())) {

            this.unReadChatsSessions['sesion'] = 1;
            const unReadChatsCountAnterior = this.unReadChatsCount;
            this.unReadChatsCount = 0;
            for (const key in this.unReadChatsSessions) {
                if (key) {
                    this.unReadChatsCount ++;
                }
            }
            if (unReadChatsCountAnterior !== this.unReadChatsCount) {
                console.log('existe un nuevo mensaje llegando...');
                // Hay que emitir una notificación local de que ha llegado un nuevo mensaje
                this.notificationService.showLocalNotification('Nuevo mensaje', {});
            }
            }
        }
        };
        this.socket.on('chat-remove-unreads', this.chatRemoveUnreadListeners);

        // this.socket.removeListener('chat-create-session', this.chatCreateSessionListeners);
        // this.socket.removeListener('chat-new-message', this.chatNewMessageListeners);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void {
        const value = event.target.value.toLowerCase();

        if ( value === '' )
        {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void {
        event.stopPropagation();

        for ( let i = 0; i < this.shortcutItems.length; i++ )
        {
            if ( this.shortcutItems[i].url === itemToToggle.url )
            {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
}
