import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
const URL = environment.url;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
  ) {
  }


  requestPushNotificationsPermission(): void {
    console.log(Notification.permission);
    if (!('Notification' in window)) {
      console.log('El navegador no soporta notificaciones');
      return;
    }
    if (Notification.permission === 'granted') {
      console.log('Ya contaba con permiso de notificación');
      return;
    }
    
    console.log('hacer request');
    Notification.requestPermission( (permission) => {
      if (permission === 'granted') {
        console.log('Habilitado para recibir notificaciones WEB');
      }
    });
  
  }

  showLocalNotification = (titulo: string, options: any) => {
    new Notification(titulo);
  }

}
