import { FuseNavigation } from '@fuse/types';
import { Params } from '@angular/router';
import { id } from '@swimlane/ngx-charts';

export const navigationAdmin: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Administrar',
        translate: 'NAV.APPLICATIONS',
        type     : 'group', 
        role: ['a', 'o', 'p'],
        children : [
            {
                id       : 'administrador-list',
                title    : 'Servicio General',
                type     : 'collapsable',
                role     : ['a'],
                children: [
                    {
                        id       : 'servicio-general-list',
                        title    : 'Servicios Generales',
                        type     : 'item',
                        url      : '/administrador/servicio-general-list',
                        role     : ['a']
                    },
                    {
                        id       : 'paquete-list',
                        title    : 'Paquetes',
                        type     : 'item',
                        url      : '/administrador/paquete-list',
                        role     : ['a']
                    },
                ]
            },
            {
                id       : 'plan-proveedor-list',
                title    : 'Planes',
                type     : 'collapsable',
                role     : ['a'],
                children : [
                    {
                        id       : 'persona-list',
                        title    : 'Plan de proveedores',
                        type     : 'item',
                        url      : '/proveedor/plan-proveedor-list',
                        role     : ['a']
                    }
                ]
            },
            {
                id       : 'configuraciones',
                title    : 'Configuraciones',
                type     : 'collapsable',
                role     : ['a', 'o', 'p'],
                children : [
                    {
                        id       : 'configuraciones-import-export',
                        title    : 'Importaciones/Exportaciones',
                        type     : 'collapsable',
                        role     : ['a', 'o', 'p'],
                        children : [
                            {
                                id       : 'configuraciones-import',
                                title    : 'Importaciones',
                                type     : 'item',
                                url      : '/import-export/configuraciones-list/import',
                                role     : ['a', 'o', 'p']
                            },
                            {
                                id       : 'configuraciones-export',
                                title    : 'Exportaciones',
                                type     : 'item',
                                url      : '/import-export/configuraciones-list/export',
                                role     : ['a']
                            }
                        ]
                    },
                    {
                        id       : 'configuraciones-notificaciones-email',
                        title    : 'Notificaciones',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [
                            {
                                id       : 'notificaciones-app',
                                title    : 'Push',
                                type     : 'item',
                                url      : '/notificaciones/notificaciones-app-list/notificaciones',
                                role     : ['a']
                            },
                            {
                                id       : 'notificaciones-app',
                                title    : 'SMS',
                                type     : 'item',
                                url      : '/notificaciones/notificaciones-app-list/sms',
                                role     : ['a']
                            },
                            {
                                id       : 'notificaciones-email',
                                title    : 'Email',
                                type     : 'item',
                                url      : '/notificaciones/notificaciones-app-list/email',
                                role     : ['a']
                            }
                        ]
                    }, /* {
                        id       : 'configuraciones-programador-tareas',
                        title    : 'Programador de Tareas',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [
                            {
                                id       : 'programador-tareas-app',
                                title    : 'Programador de Tareas',
                                type     : 'item',
                                url      : '/programador-tareas/programador-tareas-list',
                                role     : ['a']
                            }
                        ]
                    }, */{
                        id       : 'chat-member',
                        title    : 'Miembros del Chat',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [
                            {
                                id       : 'chat-member-list',
                                title    : 'Miembros del Chat',
                                type     : 'item',
                                url      : '/chat-member/chat-member-list',
                                role     : ['a']
                            },
                        ]
                    }, {
                        id       : 'parametros-opciones',
                        title    : 'Parámetro de Opciones',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [{
                            id       : 'parametro-opcion-list',
                            title    : 'Definición de parámetros',
                            type     : 'item',
                            url      : '/general/tipo-parametro-opcion-list/tipo-parametro-opcion',
                            role     : ['a']
                        }, {
                            id       : 'valor-parametro',
                            title    : 'Valores de parámetros',
                            type     : 'item',
                            url      : '/general/parametro-opcion-list',
                            role     : ['a']
                        }]
                    }, {
                        id       : 'noticias',
                        title    : 'Noticias',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [{
                            id       : 'noticias-list',
                            title    : 'Noticias',
                            type     : 'item',
                            url      : '/noticias/noticias-list',
                            role     : ['a']
                        }]
                    }, {
                        id       : 'consultas-dinamicas',
                        title    : 'Consultas',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [{
                            id       : 'consultas-dinamicas-list',
                            title    : 'Consultas Dinamicas',
                            type     : 'item',
                            url      : '/comandos-sql/comandos-sql-list/consulta-dinamica',
                            role     : ['a']
                        }, {
                            id       : 'consultas-graficos-list',
                            title    : 'Consultas de Graficos',
                            type     : 'item',
                            url      : '/comandos-sql/comandos-sql-list/grafico',
                            role     : ['a']
                        }, {
                            id       : 'consultas-mapa-list',
                            title    : 'Consultas de Mapas',
                            type     : 'item',
                            url      : '/comandos-sql/comandos-sql-list/mapa',
                            role     : ['a']
                        }]
                    }, {
                        id       : 'motor-regla',
                        title    : 'Motor de Reglas',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [{
                            id       : 'motor-regla-list',
                            title    : 'Reglas',
                            type     : 'item',
                            url      : '/motor-regla/motor-regla-list/regla',
                            role     : ['a']
                        }]
                    },
                    {
                        id       : 'ejecutar-sql',
                        title    : 'Correccion de datos',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [{
                            id       : 'ejecutar-sql',
                            title    : 'Ejecutar consultas sql',
                            type     : 'item',
                            url      : '/general/ejecutar-sql',
                            role     : ['a']
                        }]
        
                    },

                    {
                        id       : 'anuncios',
                        title    : 'Anuncios',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [{
                            id       : 'anuncios-list',
                            title    : 'Anuncio',
                            type     : 'item',
                            url      : '/general/anuncios-list',
                            role     : ['a']
                        }
                        // ,  {
                        //     id       : 'anuncios-list',
                        //     title    : 'Anuncio usufructo',
                        //     type     : 'item',
                        //     url      : '/anuncio-usufructo/0/0',
                        //     role     : ['a'],
                            
                        // }
                    ]
                    },
                    {
                        id       : 'apariencias',
                        title    : 'Apariencias',
                        type     : 'collapsable',
                        role     : ['a'],
                        children : [{
                            id       : 'colores-list',
                            title    : 'Colores de Precisapp',
                            type     : 'item',
                            url      : '/general/apariencias-list',
                            role     : ['a']
                        }
                        // ,  {
                        //     id       : 'anuncios-list',
                        //     title    : 'Anuncio usufructo',
                        //     type     : 'item',
                        //     url      : '/anuncio-usufructo/0/0',
                        //     role     : ['a'],
                            
                        // }
                    ]
                    }
                ]
            }
        ]
    }
];
