import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TiposService } from '../admin/general/tipos/tipos.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  // Indica si la Aplicación se encuentra en modo administrativo
  idiomas: any[];

  constructor(
    private route: Router,
    private http: HttpClient,
    private tipoService: TiposService
  ) {
    const admin = localStorage.getItem('isAdmin');
    if (admin === '') {
      localStorage.setItem('isAdmin', 'true');
    }
    this.listIdiomas();
  }
  
  switchPanel = () => {
    let isAdmin = localStorage.getItem('isAdmin');
    isAdmin = isAdmin === 'true' ? 'false' : 'true';
    localStorage.setItem('isAdmin', isAdmin);
    window.location.reload();
  }
  
  listIdiomas = () => {
    const titulo = 'idioma';

    this.tipoService.getTipos(titulo, '', 0, 10).subscribe(
      resp => {
        this.idiomas = resp['tipos'];
      },
      (error) => {
        console.log(error);
        console.log('Error al Obtener Idiomas');
      }
    );
  }

/*  getListTipos = (sigla: string) => {
    return this.http.get(environment.url + 'tipos/' + sigla);
  }*/

  getFirstIdioma = () => {
    let idiomaReturn = null;
    for (let i = 0; i < 1; i++) {
      const idioma = this.idiomas[i];
      if (idioma){
        idiomaReturn = idioma;
      }
    }
    return [idiomaReturn];
  }

  getCurrentUserId=()=>{
      try {
          return {ok:true,userId:sessionStorage.getItem("currentUser")};
      } catch (error) {
        return {ok:false,userId:0};
      }
    
  }
  
  getFirstIdiomaId = () => {
    let idiomaId = 0;
    for (let i = 0; i < 1; i++) {
      const idioma = this.idiomas[i];
      if (idioma){
        idiomaId = idioma.tip_codigo;
      }
    }
    return idiomaId;
  }

  convertJSONDataWithParams = (data, sufijo, objectName) => {
    const newValue = {};
    for (const key in data) {
      if (key.indexOf(sufijo + objectName) > -1) {
        const split = key.split('.');
        newValue[split[1]] = data[key];
      }
    }
    
    data[sufijo + '_to'] = newValue;
  }

  convertJSONData = (data, sufijo) => {
    const newValue = {};
    for (const key in data) {
      if (key.indexOf(sufijo + '_json_data.') > -1) {
        const split = key.split('.');
        newValue[split[1]] = data[key];
      }
    }
    
    data[sufijo + '_json_data'] = newValue;
  }

  convertDataJSON = (data, sufijo) => {
    if (data && data[sufijo + '_json_data']) {
      const newValue = {};
      for (const key in data[sufijo + '_json_data']) {
        if (key) {
          data[sufijo + '_json_data.' + key] = data[sufijo + '_json_data'][key];
        }
      }
    }
  }

  ab2str(buf): string {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  }

  str2ab(str): ArrayBuffer {
    const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    const bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  imageToDataUri(img, width, height): string {
    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;
    // draw source image into the off-screen canvas:
    
    ctx.drawImage(img, 0, 0, width, height);
    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }
}
