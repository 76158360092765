export const environment = {
    version : "1.16.4",
    production: true,
    homologacion: false,
    firebaseConfig: {
        apiKey: 'AIzaSyBuBs9oeaHilyDEZD_3-ZHxfkZamwNmc1w',
        authDomain: 'precisapp-cf52b.firebaseapp.com',
        databaseURL: 'https://precisapp-cf52b.firebaseio.com',
        projectId: 'precisapp-cf52b',
        storageBucket: 'precisapp-cf52b.appspot.com',
        messagingSenderId: '1058096056215',
        appId: '1:1058096056215:web:9e32bb5efdf2319a3926d2',
        measurementId: 'G-Q45F5JY4EN'
      },
    hmr: false,
    url: 'https://server.precis-app.net/api/',
    urlBase: 'https://server.precis-app.net',
    publicAWSHost : 'https://s3.amazonaws.com/precis-app.net/app/',
    firestorePreffix : 'p_'

};
