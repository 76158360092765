import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return this.authService.isLogged.pipe(
      take(1),
      map((isLogged: boolean) => {
        if (isLogged) {
          return isLogged;
        } else {
          this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url } });
          return isLogged;
        }
      })
    );
    // if (this.authService.getCurrentUser()) {
    //   // Si inicia sesión entonces retorna true
    //   return true;
    // } 
    
    // // si no inicia sesión entonces que redirija a la pagina de login con la URL de retorno y devuelva falso
    // this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url } });
    // return false;
    
  }

}
