import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TabsControlService } from 'app/services/tabs.service';

@Component({
    selector     : 'tab-content',
    templateUrl  : './tab-content.component.html',
    styleUrls    : ['./tab-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TabContentComponent implements OnInit
{
    tabSelected;
    /**
     * Constructor
     */
    constructor(
        public tabsControlService : TabsControlService,
        private router : Router
    )
    {
    }

    ngOnInit(): void {
        this.tabSelected = this.tabsControlService.tabs[this.tabsControlService.selectedIndex];
        
    }
    closeTab (innerTabs, key) {
        
        const index = innerTabs.findIndex(tab => tab.key == key);
        //console.log("Index seleccionado", innerTabs, key, index);
        innerTabs.splice(index, 1);
    }

    /**
     * Filtra las tabs que tienen layout == tab para mostrar en la lista de pestanas de la Aplicacion
     * @param tab 
     * @param filterArgs 
     * @returns 
     */
    filterNoTab = (tab, filterArgs: any[]) => {
        if (tab.innerTabs[0] && (tab.innerTabs[0].data.layout === 'tab')) {
          return true;
        }
        return false;
    }

    selectedTabChange(tabChangeEvent: MatTabChangeEvent) {
        //console.log("selectedTabChange", tabChangeEvent.index);
        const innerTabSelected = this.tabsControlService.getTabSelected().innerTabs[tabChangeEvent.index];
        this.router.navigate([innerTabSelected.url]);
    }

    focusChange(tabChangeEvent: MatTabChangeEvent) {
        //console.log("focusChange", tabChangeEvent.index);
    }
    
}
