import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'app/services/auth.service';

@Injectable()
export class AdminInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {

    }

    /**
     * Intercepta las peticiones http y agrega automaticamente el header authorization
     * 
     * @param req 
     * @param next 
     * @returns 
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // console.log(req);
        if (
            !(req.url.includes('login')) && !(req.url.includes('tipos')) && 
            !(req.url.includes('password')) && !(req.url.includes('register')) && 
            !(req.url.includes('validation') && !(req.url.includes('email-by-token'))
            ) 
        ) {
            //console.log("this.authService.userTokenValue", this.authService.userTokenValue);
            const authToken = this.authService.userTokenValue;
            if (this.authService.userTokenValue) {
                const authReq = req.clone({
                    setHeaders: {
                        authorization: authToken
                    }
                });
                return next.handle(authReq);
            }
            // console.log('enter', authReq);
            return next.handle(req);
        }
        // console.log(req);
        return next.handle(req);
    }
}
