<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo" (click)="toHome()">
        <img class="logo-icon" src="assets/images/logos/fuse.svg">
        <span class="logo-text secondary-text">PrecisApp</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h5 version">V {{ environment.version }} - {{ currentLoginType | uppercase}}</div>
        <div class="h3 username">{{ userData.per_nombre !== null ? userData.per_nombre : 'Admin'}}</div>
        <div class="h5 email hint-text mt-8">{{ userData.per_email !== null ? userData.per_email : 'admin@tips.com.py' }}</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="{{ userData.per_avatar !== null ? environment.publicAWSHost + userData.per_avatar : 'assets/images/avatars/Velazquez.jpg' }}">
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

    <div class="panelSwitch" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
        <a class="botonSwitch" href="/{{ ruta }}" (click)="appService.switchPanel()">{{ isAdmin === 'true' ? 'Ir a Administrar' : 'Ir a Panel de Control' }}</a>
    </div>

</div>
